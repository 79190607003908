.hero {
    height: 90vh;
    width: 100vw;
}

video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.hero-text {
    position: relative;
    height: 60%;
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fafafa;
    margin-top: 300px;
}

h1 {
    font-size: 3rem;
}

h3 {
    margin-top: 0;
    font-size: 1.5rem;
    width: 65%;
}

.signup-button {
    width: 200px;
    height: 50px;
    margin-top: 40px;
}

.signup {
    color: white;
    font-size: 1.2rem;
    border: none;
}

.signup:hover {
    opacity: 1;
    color: white;
}
