.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 80%;
    border-radius: 5px;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    height: 100%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
}