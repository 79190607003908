* {
    max-width: 100%;
    box-sizing: border-box;
    /* border: 1px solid black; */
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

#reschedule-class:hover {
    background-color: lightgray;
    cursor: pointer;
}

body {
    background-color: #f5f5f5;
    font-family: "Inter", sans-serif;
}

section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 90%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 65%;
    width: 100%;
    border-radius: 5px;
    overflow-x: auto;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

form > * {
    padding: 15px 0;
}

label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

input {
    border-radius: 5px;
    height: 35px;
    width: 80%;
    text-align: center;
    border: 2px solid grey;
    font-family: "Inter";
}

button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
}

button:hover {
    cursor: pointer;
    opacity: 0.8;
}

.submit-button {
    margin: 0;
    margin-top: 10px;
}

.table-container {
    width: 100%;
    padding: 0;
}

table {
    color: #474973;
    font-weight: 600;
    margin-bottom: 40px;
    background-color: white;
    padding: 30px 10px 0 10px;
}

.num {
    text-align: right;
}

th {
    padding: 10px;
    background-color: #f1dac4;
    color: #474973;
}

td {
    padding: 0 10px;
}
