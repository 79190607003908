.edit {
    height: 40px;
    max-width: 50px;
    padding: 7px;
}

.del {
    height: 50px;
    max-width: 50px;
    padding: 3px;
    margin-top: 10px;
}

.icon {
    transition: 300ms;
}

.icon:hover {
    opacity: 0.5;
    cursor: pointer;
}
