.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(80, 80, 80, 0.7);
}

.modal {
    border-radius: 10px;
    background-color: white;
    width: 60%;
    padding: 3%;
}